import * as React from "react"
import mixpanel from "mixpanel-browser";
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/seo"
import "../style.css"

mixpanel.init("63a14994f2f8d36d2e173c2290c19a4f");

function Bolt() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd" />
    </svg>
    )
}

function Search() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
</svg>
    )
}

function Chart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
    </svg>
  )
}

function Mobile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
    </svg>
    )
}

function Social() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
    <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
    </svg>
  )
}

function Web() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
    </svg>
  )
}

// data
const links = [
  {
    text: "One Step setup",
    description: "Provide us with your RSS feed for your podcast and have a website for your podcast ready in 30 seconds for free. Your website will automatically update with your RSS feed.",
    color: "#E95800",
    icon: <Bolt />
  },
  {
    text: "SEO Ready",
    description: "Automatically create SEO friendly pages for each episode of your podcast, add transcripts and show notes.",
    color: "#663399",
    icon: <Search />
  },
  {
    text: "Analytics Built-In",
    description: "Analyze and grow your audience with no setup using a fully automated podcast analytics dashboard.",
    color: "#1099A8",
    icon: <Chart />
  },
  {
    text: "Optimized for Mobile",
    description:
      "Mobile optimized website for your podcast and audio player that loads instantly.",
    color: "#BC027F",
    icon: <Mobile />
  },
  {
    text: "Built for Social",
    description: "Serve your listeners where they are and keep all your social media links in one place.",
    color: "#0D96F2",
    icon: <Social />
  },
  {
    text: "Custom Domain and Branding",
    badge: true,
    description: "Bring your own personalaized domain, logo and more.",
    color: "#663399",
    icon: <Web />
  },
]

// markup
function IndexPage() {
  const formRef = React.useRef(null);
  
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      mixpanel.track("View Landing Page", {
        "referrer": document.referrer
      });
    }
  }, [])

  return (
    <>
    <SEO/>
      <main className="my-12 md:p-12 p-4 max-w-4xl mx-auto">
        <StaticImage
          className="pv-6"
          src="../images/logo.svg" 
          alt="Podlet Logo"
          placeholder="blurred"
          layout="fixed"
          width={60}
          height={60}
        />
        <h1 className="font-black text-5xl md:text-6xl text-emerald-500 mx-auto mt-6">
          Give your podcast a professional website.
        </h1>
        <h2 className="font-bold text-2xl py-12 text-slate-500 mx-auto">
          Create a website for your podcast in a singe step using your RSS feed in minutes.
        </h2>
        <form name="waitlist" data-netlify="true" ref={formRef}>
          <input type="hidden" name="form-name" value="waitlist" />
          <input 
            className="rounded-l-lg p-2 md:p-3 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" 
            name="email" 
            type="email" 
            placeholder="Enter Your Email"
          />
          <button 
            className="rounded-r-lg bg-emerald-400 text-white font-bold p-2 md:p-3 uppercase border-emerald-500 border-t border-b border-r"
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              mixpanel.track("Join Waiting List");
              formRef.current?.submit();
            }}
            >
            Join the waitlist
          </button>  
        </form>
        <h4 className="font-bold text-lg text-slate-500 py-6">Try it out</h4>
        <div className="pb-12 pt-2 flex items-center">
          <a href="https://listen.podlet.app/the-daily">
            <StaticImage 
              src="../images/the-daily.jpeg" 
              alt="The Daily Podcast Artwork"
              placeholder="blurred"
              className="mr-6"
              layout="constrained"
              width={100}
              height={100}
            />
          </a>
          <a href="https://listen.podlet.app/bbc-global-news">
            <StaticImage 
              src="../images/bbc-global-news.jpeg" 
              alt="BBC Global News Podcast Artwork"
              placeholder="blurred"
              className="mr-6"
              layout="constrained"
              width={100}
              height={100}
            />
          </a>
          <a href="https://listen.podlet.app/sub-club">
            <StaticImage 
              src="../images/sub-club.jpeg" 
              alt="The Sub Club Podcast Artwork"
              placeholder="blurred"
              layout="constrained"
              width={100}
              height={100}
            />
          </a>
        </div>
        <ul>
          {links.map(link => (
            <li key={link.text} style={{ color: link.color }}>
              <h3
                className="font-bold text-lg flex items-center py-2"
              > {link.icon}
                {link.text}
              </h3>
              <p className="text-lg text-slate-500">{link.description}</p>
            </li>
          ))}
        </ul>
      </main>
    </>
  )
}

export default IndexPage
